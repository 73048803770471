exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-consultation-jsx": () => import("./../../../src/pages/consultation.jsx" /* webpackChunkName: "component---src-pages-consultation-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-psychologist-jsx": () => import("./../../../src/pages/psychologist.jsx" /* webpackChunkName: "component---src-pages-psychologist-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-service-jsx": () => import("./../../../src/pages/service.jsx" /* webpackChunkName: "component---src-pages-service-jsx" */),
  "component---src-templates-consultation-template-jsx": () => import("./../../../src/templates/consultationTemplate.jsx" /* webpackChunkName: "component---src-templates-consultation-template-jsx" */),
  "component---src-templates-english-template-jsx": () => import("./../../../src/templates/englishTemplate.jsx" /* webpackChunkName: "component---src-templates-english-template-jsx" */),
  "component---src-templates-expert-template-jsx": () => import("./../../../src/templates/expertTemplate.jsx" /* webpackChunkName: "component---src-templates-expert-template-jsx" */),
  "component---src-templates-post-template-jsx": () => import("./../../../src/templates/postTemplate.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */),
  "component---src-templates-team-template-jsx": () => import("./../../../src/templates/teamTemplate.jsx" /* webpackChunkName: "component---src-templates-team-template-jsx" */),
  "component---src-templates-topic-template-jsx": () => import("./../../../src/templates/topicTemplate.jsx" /* webpackChunkName: "component---src-templates-topic-template-jsx" */)
}

